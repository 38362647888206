<template>
  <div
    v-loading="loading"
    class="application-list"
  >
    <template v-if="applyList.length">
      <div class="top-content">
        <span>申请者</span>
        <div
          class="all-button operate"
          :class="{'disabled': disabled }"
          @click="handleAllAgree"
        >
          全部同意
        </div>
      </div>
      <scroll-bar
        class="main-scroll apply-list"
        :flex-layout="true"
      >
        <div
          v-for="(item, index) in applyList"
          :key="index"
          class="apply-list-item"
        >
          <div class="left">
            <div class="avatar-content">
              <logo-panel
                :info="item"
                class="avatar"
              />
            </div>
            <div class="name">
              {{ item.name || '新用户' }}
            </div>
          </div>
          <div class="right">
            <div
              class="operate"
              :class="{'disabled': item.examineStatus !== 1}"
              @click="handleAgree(item)"
            >
              {{ item.examineStatus===1 ? '同意' :'已同意' }}
            </div>
          </div>
        </div>
      </scroll-bar>
    </template>
    <div
      v-else
      class="no-empty"
    >
      暂无申请名单
    </div>
  </div>
</template>
<script>
import LogoPanel from '@/views/components/LogoPanel';
import ScrollBar from '@/components/ScrollBar';
import { getApplicationList, setAgreeApply, setAgreeApplyAll } from '@/services/room';

export default {
  name: '',
  components: {
    LogoPanel,
    ScrollBar,
  },
  data() {
    return {
      roomId: this.$route.params.id,
      loading: true,
      applyList: [],
      disabled: true,
    };
  },
  mounted() {
    this.getApplicationList();
  },

  methods: {
    async getApplicationList() {
      this.loading = true;
      try {
        const data = await getApplicationList(this.roomId);
        if (data?.data) {
          this.applyList = Object.freeze(data.data);
          this.applyList.forEach((i) => {
            if (i.examineStatus === 1) {
              this.disabled = false;
            }
          });
        }
      } catch (error) {
        // console.log(error);
      } finally {
        this.loading = false;
      }
    },

    // 同意申请
    async handleAgree(item) {
      if (item.examineStatus === 2) return;
      try {
        this.disabled = true;
        await setAgreeApply({
          liveRoomId: this.roomId,
          uid: item.uid,
          examineStatus: 2,
        });
        const formatData = Object.freeze(this.applyList);
        this.applyList = formatData.map((i) => {
          if (i.uid === item.uid) { i.examineStatus = item.examineStatus === 1 ? 2 : 1; }
          if (i.examineStatus === 1 && this.disabled) {
            this.disabled = false;
          }
          return i;
        });
        this.$forceUpdate();
      } catch (error) {
        // console.log(error);
      }
    },
    // 全部同意申请
    async handleAllAgree() {
      if (this.disabled) return;
      await setAgreeApplyAll(this.roomId);
      const formatData = Object.freeze(this.applyList);
      this.applyList = formatData.map((i) => {
        i.examineStatus = i.examineStatus === 2;
        return i;
      });
      this.$forceUpdate();
      this.disabled = true;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ {
  .el-loading-mask {
    background-color: #151a28;
  }
}

.top-content {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 16px 0;

  >span {
    line-height: 28px;
  }
}

.no-empty {
  text-align: center;
  padding-top: 156px;
  font-size: 12px;
  color: #666b71;
  line-height: 18px;
}

.apply-list {
  max-height: 378px;
  overflow-y: auto;
  padding-right: 14px;

  &.main-scroll {
    &::-webkit-scrollbar-track-piece {
      background: #151a28;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2);
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 3.5px;
    }
  }
}

.apply-list-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  .left {
    display: flex;

    .name {
      line-height: 40px;
    }
  }

  .right {
    padding-top: 6px;
  }
}

.operate {
  width: 68px;
  height: 28px;
  background: #3e74ca;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  line-height: 28px;
  font-size: 12px;

  &.disabled {
    cursor: default;
    color: #aaacb1 !important;
    background: rgba(255, 255, 255, 0.1) !important;
    border: 0 !important;
  }
}

.all-button.operate {
  background: rgba(179, 187, 198, 0.12);
  color: #5a9aff;
  border: 1px solid #3e74ca;
}

/deep/ .user-logo-content {
  width: 40px;
  height: 40px;
}

.avatar {
  /deep/ .logo {
    height: 40px !important;
  }
}
</style>
